<template>
    <div class="form-signin text-center">
  
    <div v-if=user class="profile">
        <img v-if=user.profile.picture v-bind:src="'/server/1900eab6c028483d7126599ee6f50de0d27907b5c65fa90524580b4b0f9852b/'+user.profile.picture" class="picture" />
        <h3 class="name">{{user.firstName}} {{user.lastName}}</h3>
    </div>

    <hr>
        <p>Man.. can't even remember your own password. What a dipshit loser you are.</p>
      <label for="inputPassword1" class="sr-only">Reset Password</label>
      <b-form-input v-model="passwordone" type="password" class="form-control" placeholder="Password" />
  
      <label for="inputPassword2" class="sr-only">Confirm Password</label>
      <b-form-input v-model="passwordtwo" type="password" class="form-control" placeholder="Confirm Password" />

        <!-- <p style="color:white;">ADFASDF {{user}}</p> -->
      <button class="btn btn-lg btn-block" style="border: 1px solid gray; color: white; margin-top: 10px;" @click="resetaccount">Reset</button>
      <p style="text-align: justify;">{{message}}</p>
    </div>
  </template>
  
  <script>
  import { api } from "@/utils/api";
  
  export default {
    name: "Register",
    data() {
      return {
        user: null,
        passwordone: "",
        passwordtwo: "",
        message: ""
      };
    },
    created() {
        let self = this;
        api(
        `query { reset(code:"${this.$route.params.id}") { 
                user{ 
                    firstName
                    lastName
                    profile {
                        picture
                    }
                }
            }
        }`
        ).then((data) => {
            self.user = data.reset.user
        })
        .catch((e) => {
            self.$router.push({ name: "Login" });
        });
    },
    methods: {
      resetaccount() {
        if (this.passwordone !== this.passwordtwo) {
          this.message = "Passwords don't equal";
        } else {
          let self = this;
          api(
            `mutation{ reset(password:"${this.passwordone}", resetcode:"${this.$route.params.id}") { message status } }`
          ).then(data => {
            console.log(data)
            if (data.reset.status === 0) {
              self.$router.push({ name: "Login" });
            } else {
              self.message = data.reset.message;
            }
          });
        }
      }
    }
  };
  </script>
  
  <style scoped>
  html,
  body {
    height: 100%;
  }
  
  body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
  }
  
  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
  }
  .form-signin .checkbox {
    font-weight: 400;
  }
  .form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }
  .form-signin .form-control:focus {
    z-index: 2;
  }
  input {
    margin: 2.5px 0 2.5px 0;
  }
  input:focus input:active {
    outline: none;
  }
.profile {
    margin-top: 15px;
    text-align: center;
}
.profile > .picture {
  width:200px;
  height:200px;
  object-fit:cover;
  border-radius:50%;
}
.profile > .name {
  margin-top: 10px;
  object-fit:cover;
  border-radius:50%;
}
</style>